import React, { useState, useEffect } from 'react';
import style from './LoginBox.module.scss';

import { 
    ChooseProvider,
    LoadingProvider,
    EmailPassword,
    Loading,
    Registration,
    Error,
    Success,
    ChooseSession,
    SuccessExternalSignin,
    SuccessExternalLink,
    SuccessExternalRegistration,
    Recovery,
    PasswordReset,
    MFACode,
    ConfirmEmail,
    AccountLocked,
} from './views';

import { Illustration } from './illustrations/Illustration';

import { motion, AnimatePresence, useReducedMotion } from "framer-motion";
import { ErrorMessage } from '../../lib/herke-passport/hig-passport-api-client';
import { SuccessContinueSession, SuccessRecovery, SuccessPasswordReset, SuccessEmailConfirmation, SuccessSignout } from './views/Success';
import { NewUserInfo } from './views/Registration';

interface LoginBoxProps {
    view: LoginBoxView,
    appName?: string,
    environmentName?: string,
    activeIdentityProvider?: IdentityProvider | null,
    identityProviders?: Array<IdentityProvider>,
    session?: {
        displayName?: string,
        email?: string
    } | null,
    loading?: boolean,
    error?: ErrorMessage,
    errorReturnUrl?: string,

    onValidateRegistrationUsername?: (username: string) => Promise<{isValid: boolean, message: string}> | {isValid: boolean, message: string},
    onValidateRegistrationPassword?: (password: string) => Promise<{isValid: boolean, message: string}> | {isValid: boolean, message: string},

    onEmailPassword?: ({username, password, isPersistent} : { username: string, password: string, isPersistent?: boolean}) => Promise<{result: boolean, message: string}>
    onContinueWithSession?: () => void,
    onChooseProvider?: (provider: IdentityProvider) => void,
    onRegister?: (newUser: NewUserInfo) => void,
    onPasswordReset?: (password: string) => void,
    onRecovery?: (username: string) => void,

    onRegisterView?: () => void,
    onLoginView?: () => void,
    onRecoveryView?: () => void,
    onChooseProviderView?: () => void
}

export interface IdentityProvider {
    name: string,
    displayName?: string,
    icon?: JSX.Element
}

export enum LoginBoxView {
    AccountLocked,
    ChooseProvider,
    ChooseSession,
    EmailPassword,
    Loading,
    LoadingProvider,
    Register,
    PasswordReset,
    MFACode,
    Recovery,
    Success,
    SuccessExternalSignIn,
    SuccessExternalRegistration,
    SuccessExternalLink,
    SuccessContinueSession,
    SuccessRegistration,
    SuccessRecovery,
    SuccessPasswordReset,
    SuccessEmailConfirmation,
    SuccessSignout,
    Error,
    ConfirmEmail
}

export const AnimateDiv = ({ id, children }: { id: number, key: number, children: any }) => {
    const shouldReduceMotion = useReducedMotion();

    return (
        <motion.div
            className={style['higpp-l-login-box-view-wrapper']}
            initial={
                (shouldReduceMotion)
                    ? { opacity: 0, x: 0 }
                    : { opacity: 0, x: id > 1 ? 25 : -25 }
            }
            animate={
                (shouldReduceMotion)
                    ? { opacity: 1, x: 0 }
                    : { opacity: 1, x: 0 }
            }
            transition={{
                type: "tween",
                ease: "easeOut"
            }}
            exit={
                (shouldReduceMotion)
                    ? { opacity: 0, x: 0 }
                    : { opacity: 0, x: id > 1 ? 25 : -25 }
            }
        >
            {children}
        </motion.div>
    )
};

/** Main login box component */
export const LoginBox = (props: LoginBoxProps) => {
    const [height, setHeight] = useState<string>('15rem');
    const [activeProvider, setActiveProvider] = useState<IdentityProvider | null>(null);

    const [showAccountCreateOption, setShowAccountCreateOption] = useState<boolean>(false);
    const [showAccountRecoveryOption, setShowAccountRecoveryOption] = useState<boolean>(false);

    /**
     * Various pages can defines their own height, requiring the wrapping
     * div to increase or decrease height
     */
    useEffect(() => {
        const calculateContentHeight = () => {
            switch (props.view) {
                case LoginBoxView.ChooseProvider: return '10rem';
                case LoginBoxView.Loading:
                case LoginBoxView.LoadingProvider: return '13rem';
                case LoginBoxView.Register: return '20rem';
                case LoginBoxView.PasswordReset: return '20rem';
                default: return '16rem';
            }
        }

        setHeight(calculateContentHeight());
    }, [props.view]);

    /**
     * If the props.view changes, rethink if the various support options should
     * be shown
     */
    useEffect(() => {
        setShowAccountCreateOption(
            props.view === LoginBoxView.ChooseProvider);

        setShowAccountRecoveryOption(
            props.view === LoginBoxView.ChooseProvider
            || props.view === LoginBoxView.EmailPassword
            || props.view === LoginBoxView.LoadingProvider
            || props.view === LoginBoxView.MFACode);
    }, [props.view, props.onRegisterView]);

    /** 
     * If the user decides to continue with the current session, continue
     * with the original authentication request
     */
    const onContinueWithSession = () => {
        props.onContinueWithSession && props.onContinueWithSession();
    }

    const onChooseProvider = (provider: IdentityProvider) => {
        setActiveProvider(provider);
        props.onChooseProvider && props.onChooseProvider(provider);
    }

    /** Check if the user agent is props.viewing this on iOS */
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    return (
        <div className={style['higpp-c-login-wrapper']}>
            <div className={style['higpp-c-login-box']}>
                <div className={style['higpp-l-login-box-title-wrapper']}>
                    <div className={style['inner-wrapper']}>
                        <div className={style['higpp-l-login-box-app-name']}>
                            {props.view === LoginBoxView.Register
                                ? "Nieuw account"
                                : props.appName
                            }
                        </div>
                        {props.environmentName 
                          && !props.environmentName.toLocaleLowerCase().includes("production") 
                          && !props.environmentName.toLocaleLowerCase().includes("live") 
                          && !props.environmentName.toLocaleLowerCase().includes("{Temporary}") &&
                            <div className={style['higpp-l-login-box-app-environment']}>
                                {props.environmentName === "{Temporary}" ? "Live" : props.environmentName}
                            </div>
                        }
                    </div>
                    <Illustration />
                </div>
                <div
                    style={{height: height, transition: '.3s'}}
                    className={style['higpp-l-login-box-content-wrapper'] + (iOS ? ' ' + style['--ios'] : '')}
                >
                    <AnimatePresence>
                        {{
                            [LoginBoxView.AccountLocked]:
                                <AnimateDiv key={0} id={0}>
                                    <AccountLocked/>
                                </AnimateDiv>,
                            [LoginBoxView.ChooseProvider]:
                                <AnimateDiv key={1} id={1}>
                                    <ChooseProvider
                                        emailPasswordEnabled={props.onEmailPassword !== null}
                                        identityProviders={props.identityProviders}
                                        onChooseProvider={onChooseProvider}
                                        session={props.session}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.ChooseSession]:
                            <AnimateDiv key={2} id={2}>
                                <ChooseSession
                                    session={props.session}
                                    onContinueWithSession={onContinueWithSession}
                                    onChooseProvider={props.onChooseProviderView}
                                />
                            </AnimateDiv>,
                            [LoginBoxView.EmailPassword]:
                                <AnimateDiv key={3} id={3}>
                                    <EmailPassword
                                        loading={props.loading}
                                        onBack={props.onChooseProviderView}
                                        onSubmit={props.onEmailPassword}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.Loading]:
                                <AnimateDiv key={4} id={4}>
                                    <Loading />
                                </AnimateDiv>,
                            [LoginBoxView.LoadingProvider]:
                                <AnimateDiv key={5} id={5}>
                                    <LoadingProvider
                                        provider={activeProvider!}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.Register]:
                                <AnimateDiv key={6} id={6}>
                                    <Registration
                                        loading={props.loading}
                                        onValidateRegistrationUsername={props.onValidateRegistrationUsername}
                                        onValidateRegistrationPassword={props.onValidateRegistrationPassword}
                                        onSubmit={props.onRegister}
                                        onBack={props.onChooseProviderView}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.Recovery]:
                                <AnimateDiv key={7} id={7}>
                                    <Recovery
                                        loading={props.loading}
                                        onBack={props.onChooseProviderView}
                                        onSubmit={props.onRecovery}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.PasswordReset]:
                                <AnimateDiv key={8} id={8}>
                                    <PasswordReset
                                        loading={props.loading}
                                        onSubmit={props.onPasswordReset}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.MFACode]:
                                <AnimateDiv key={9} id={9}>
                                    <MFACode
                                        loading={props.loading}
                                        onBack={props.onChooseProviderView}
                                        onSubmit={props.onPasswordReset}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessRegistration]:
                                <AnimateDiv key={10} id={10}>
                                    <Success />
                                </AnimateDiv>,
                            [LoginBoxView.Success]:
                                <AnimateDiv key={11} id={11}>
                                    <Success />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessSignout]:
                                <AnimateDiv key={12} id={12}>
                                    <SuccessSignout />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessExternalSignIn]:
                                <AnimateDiv key={13} id={13}>
                                    <SuccessExternalSignin />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessExternalRegistration]:
                                <AnimateDiv key={14} id={14}>
                                    <SuccessExternalRegistration />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessExternalLink]:
                                <AnimateDiv key={15} id={15}>
                                    <SuccessExternalLink />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessContinueSession]:
                                <AnimateDiv key={16} id={16}>
                                    <SuccessContinueSession />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessRecovery]:
                                <AnimateDiv key={17} id={17}>
                                    <SuccessRecovery onBack={props.onLoginView} />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessPasswordReset]:
                                <AnimateDiv key={18} id={18}>
                                    <SuccessPasswordReset />
                                </AnimateDiv>,
                            [LoginBoxView.SuccessEmailConfirmation]:
                                <AnimateDiv key={19} id={19}>
                                    <SuccessEmailConfirmation />
                                </AnimateDiv>,
                            [LoginBoxView.Error]:
                                <AnimateDiv key={20} id={20}>
                                    <Error 
                                        error={props.error}
                                        errorReturnUrl={props.errorReturnUrl}
                                    />
                                </AnimateDiv>,
                            [LoginBoxView.ConfirmEmail]:
                                <AnimateDiv key={21} id={21}>
                                    <ConfirmEmail/>
                                </AnimateDiv>,
                        }[props.view]}
                    </AnimatePresence>
                </div>
                <ul className={style['higpp-l-login-box-support-wrapper']}>
                    {showAccountRecoveryOption && props.onRecoveryView &&
                        <li key={2}>
                            <button onClick={props.onRecoveryView}>
                                Hulp bij inloggen
                            </button>
                        </li>
                    }
                    {showAccountCreateOption && props.onRegisterView &&
                        <li key={0}>
                            <button onClick={props.onRegisterView}>
                                Nog geen account?
                            </button>
                        </li>
                    }
                </ul>
            </div>
        </div>
    );
}

LoginBox.defaultProps = {
    appName: "Herke ICT Group"
}