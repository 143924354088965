import React, { useState, useEffect, useRef } from 'react';
import { Input, Form, ButtonMenu, Button, Paragraph } from '@herke/herkeid-react';

import { validateEmail } from './../../../../lib';

interface RecoveryProps {
    loading?: boolean,
    onSubmit?: (email: string) => void,
    onBack?: () => void
}

export const Recovery = (props: RecoveryProps) => {
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState<string | null>("");

    const [disabled, setDisabled] = useState(true);

    const timer = useRef<any>(false);

    useEffect(() => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            if (username.length > 0 && !validateEmail(username)) {
                setUsernameError("Vul een geldig e-mailadres in");
            }
            else {
                setUsernameError(null);
            }
        }, 350);
    }, [username]);

    useEffect(() => {
        if (username.length > 0 && usernameError === null)
            setDisabled(false);
        else
            setDisabled(true)
    }, [usernameError, username]);

    const submit = () => {
        props.onSubmit && props.onSubmit(username);
    }

    return (
        <div>
            <Form onSubmit={submit}>
                <Paragraph>
                    Lukt het niet om in te loggen? Vul je <span style={{whiteSpace: 'nowrap'}}>e-mailadres</span> in,
                    dan helpen we je snel verder:</Paragraph>
                <fieldset>
                    <Input
                        name="recovery-username"
                        value={username}
                        displayName="E-mailadres"
                        placeholder="e.mail@adres.nl"
                        type="email"
                        onChange={(e) => setUsername(e.target.value)}
                        disableLastPass
                        message={usernameError ? {
                            body: usernameError,
                            type: "error"
                        } : undefined}
                        autoFocus
                        disabled={props.loading}
                    />
                </fieldset>
                <ButtonMenu align="center" className="mt-5">
                    {props.onBack &&
                        <Button
                            text="Terug"
                            transparent
                            loading={props.loading}
                        className="mr-5"
                        fullWidth
                            onClick={props.onBack}
                        />
                    }
                    <Button
                        text="Account herstellen"
                        appearance="highlight"
                        loading={props.loading}
                        disabled={disabled || props.loading}
                        type="submit"
                        className="ml-3"
                        fullWidth
                    />
                </ButtonMenu>
            </Form>
        </div>
    );
}