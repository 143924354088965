import { HerkePassportApiClient as Client } from './herke-passport';
export { validateEmail, validatePassword } from './validation-helpers';

/**
 * Returns a new instance of the Herke Passport API client, which
 * includes methods for communicating with the backend systems
 */
export class HerkePassportApiClient extends Client {
    constructor(baseUrl?: string) {
        super(
            baseUrl ?? window.location.origin
        );
    }
};