import React, { useEffect, useState } from 'react';

import { ErrorMessage, SignOutDto } from './../../lib/herke-passport/hig-passport-api-client';
import { LoginBox } from '../../components';
import { LoginBoxView } from '../../components/LoginBox';
import { LoadingOverlay } from '@herke/herkeid-react';
import { HerkePassportApiClient } from '../../lib';

export const LogoutRoute = () => {
    const [error, setError] = useState<ErrorMessage>();
    const [loading, setLoading] = useState<boolean>(true);
    const [view, setView] = useState<LoginBoxView>(LoginBoxView.Loading);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const logoutId = urlParams.get('logoutId');

        const retrieveLogoutContext = async () => {
            try {
                const apiClient = new HerkePassportApiClient();
                let { result } = await apiClient.authentication_GetSignOutContext(logoutId);

                if (result.returnUrl) {
                    await apiClient.authentication_Signout(new SignOutDto({
                        logoutId: logoutId,
                        triggerExternalLogout: false
                    }));
                    
                    setView(LoginBoxView.SuccessSignout);

                    setTimeout(() => {
                        window.location.href = result.returnUrl!;
                    }, 1000);
                }
                else {
                    setView(LoginBoxView.Error);
                }
            }
            catch(error) {
                if (error.status === 404) {
                    setError(new ErrorMessage({
                        title: "Dat ging niet goed",
                        body: "Er ging iets fout. Probeer het later opnieuw"
                    }));
                }
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        retrieveLogoutContext();
    }, []);

    return (
        <div>
            {loading ? (
                <LoadingOverlay/>
            ) : (
                <LoginBox
                    view={view}
                    error={error}
                />
            )}
        </div>
    );
};