import React, { useEffect, useState, useContext } from 'react';

import { LoginBox } from '../../components';
import { LoginBoxView } from '../../components/LoginBox';
import { LoginContext } from '../../components/LoginContextProvider';

import { ErrorMessage, AccountEmailConfirmationDto } from '../../lib/herke-passport/hig-passport-api-client';
import { HerkePassportApiClient } from '../../lib';

export const ConfirmEmailRoute = () => {
    const { authenticationContext } = useContext(LoginContext);
    const [view, setView] = useState<LoginBoxView>(LoginBoxView.Loading);

    const [error, setError] = useState<ErrorMessage>();

    const { client } = authenticationContext;

    useEffect(() => {
        let params = new URLSearchParams(window.location.search),
            token = params.get("token"),
            accountId = params.get("accountId");

        if (!accountId || !token) {
            setView(LoginBoxView.Error);
            setError(new ErrorMessage({
                title: "Ongeldige aanvraag",
                body: "De aanvraag is ongeldig. Mogelijk is de link verlopen"
            }));
        }

        confirmEmail();
    }, []);

    /**
     * Attempts to confirm the email address
     */
    const confirmEmail = async () => {
        const params = new URLSearchParams(window.location.search);
    
        try {
            const apiClient = new HerkePassportApiClient();
            const accountId = params.get("accountId");
            const token = params.get("token");
    
            if (!accountId || !token) return setError(new ErrorMessage({
                title: "Ongeldige aanvraag",
                body: "De gegevens die zijn opgegeven kloppen niet. Probeer het later opnieuw"
            }));
    
            let response = await apiClient.account_EmailConfirmation(accountId, new AccountEmailConfirmationDto({
                token: token
            }));
    
            if (response.status === 200) {
                setView(LoginBoxView.SuccessEmailConfirmation);
            }
        }
        catch(error) {
            setError(new ErrorMessage({
                title: "Dat ging niet goed",
                body: "Mogelijk is de unieke code verlopen. Probeer het later opnieuw."
            }))
        }
    }

    return (
        <div>
            <LoginBox
                appName={client?.displayName!}
                environmentName={client?.environment?.displayName!}
                error={error}
                errorReturnUrl="/recovery"
                view={view}
            />
        </div>
    );
};