import React, { useEffect, useState } from 'react';

import { ErrorMessage } from './../../lib/herke-passport/hig-passport-api-client';
import { LoginBox } from '../../components';
import { LoginBoxView } from '../../components/LoginBox';
import { LoadingOverlay } from '@herke/herkeid-react';
import { HerkePassportApiClient } from '../../lib';

export const ErrorRoute = () => {
    const [error, setError] = useState<ErrorMessage>();
    const [loading, setLoading] = useState<boolean>(true);
    const [returnUrl, setReturnUrl] = useState<string>("");

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const errorId = urlParams.get('errorId');
        const name = urlParams.get('error');
        const returnUrl = localStorage.getItem("HIG-PASSPORT-RETURN-URL");

        const retrieveErrorContext = async () => {
            try {
                const apiClient = new HerkePassportApiClient();
                let { result } = await apiClient.authentication_GetErrorContext(null, name, errorId);

                setError(result);

                if (returnUrl)
                    setReturnUrl(
                        window.location.pathname.replace("/error", "/authorize") + "?ReturnUrl=" + encodeURIComponent(returnUrl)
                    );
            }
            catch(error) {
                if (error.status === 404) {
                    setError(new ErrorMessage({
                        title: "Dat ging niet goed",
                        body: "Er ging iets fout. Probeer het later opnieuw"
                    }));
                }
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        retrieveErrorContext();
    }, []);

    return (
        <div>
            {loading ? (
                <LoadingOverlay/>
            ) : (
                <LoginBox
                    view={LoginBoxView.Error}
                    error={error}
                    errorReturnUrl={returnUrl}
                />
            )}
        </div>
    );
};