import React from 'react';
import style from './ConfirmEmail.module.scss';

import { Mail } from 'react-feather';
import { Button, ButtonMenu, Heading, Paragraph } from '@herke/herkeid-react';

interface ConfirmEmailProps {
    title?: string,
    message?: string,
}

export const ConfirmEmail = (props: ConfirmEmailProps) => {
    return (
        <div className={style['higpp-l-login-box-confirm-email']}>
            <div className={style['confirm-email-icon']}>
                <Mail/>
            </div>
            <Heading type="h2" className="mt-5">{props.title}</Heading>
            <Paragraph className="my-5">{props.message}</Paragraph>
            <ButtonMenu align="center" className="mt-10">
                <Button
                    text="Moment"
                    loadingText="Moment..."
                    appearance="default"
                />
            </ButtonMenu>
        </div>
    );
}

ConfirmEmail.defaultProps = {
    title: "Bevestig je e-mailadres",
    message: "Je ontvangt binnen enkele minuten een bericht om je e-mailadres te bevestigen. Tot die tijd werken mogelijk niet alle services.",

}