import React from 'react';
import style from './Loading.module.scss';
import { IdentityProvider } from '../../index';
import { LoadingSpinner } from '@herke/herkeid-react';

interface LoadingProps {
    provider?: IdentityProvider
}

export const Loading = (props: LoadingProps) => {
    return (
        <div className={style['higpp-l-login-box-loading']}>
            <LoadingSpinner />
        </div>
    )
}