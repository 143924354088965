import React from 'react';
import style from './../Error/Error.module.scss';

import { Error as ErrorComponent } from '@herke/herkeid-react';

interface AccountLockedProps { }

export const AccountLocked = (props: AccountLockedProps) => {   
    return (
        <div className={style['higpp-l-login-box-error']}>
            <ErrorComponent
                title="Wacht met je volgende poging"
                body="Je hebt te vaak geprobeerd in te loggen. Over 5 minuten kun je het opnieuw proberen."
            />
        </div>
    );
}