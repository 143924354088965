import React from 'react';
import style from './ChooseSession.module.scss';

import { EntityGrid, EntityGridItem, EntityGridItemHeading, ProfilePicture, Paragraph } from '@herke/herkeid-react';
import { User } from 'react-feather';

interface ChooseSessionProps {
    session?: {
        displayName?: string | null,
        email?: string | null
    } | null,
    onContinueWithSession?: () => void,
    onChooseProvider?: () => void,
}

export const ChooseSession = (props: ChooseSessionProps) => {

    return (
        <div className={style['higpp-l-login-box-choose-session']}>
            <Paragraph>Wil je verder met dit account?</Paragraph>
            <EntityGrid className="mt-5">
                <EntityGridItem shadow={false} border>
                    <EntityGridItemHeading
                        displayName={props.session?.displayName!}
                        description={props.session?.email!}
                        onClick={props.onContinueWithSession}
                        icon={
                            <ProfilePicture
                                displayName={props.session?.displayName!}
                                size="small"
                            />
                        }
                    />
                </EntityGridItem>
                <EntityGridItem shadow={false}>
                    <EntityGridItemHeading
                        icon={<User/>}
                        withIconBackground={false}
                        displayName="Kies een ander account"
                        onClick={props.onChooseProvider}
                    />
                </EntityGridItem>
            </EntityGrid>
        </div>
    )
}