import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { HerkePassportApiClient } from '../../lib';

interface AuthenticationContext {
    client?: {
        displayName: string,
        environment: {
            displayName: string
        }
    },
    returnUrl?: string | null,
    session?: {
        id?: string,
        displayName?: string,
        email?: string
    } | null,
    loadingContext?: boolean
}

interface LoginContextProps {
    authenticationContext: AuthenticationContext,
    loadingContext?: boolean
}

export const LoginContext = React.createContext<LoginContextProps>({
    authenticationContext: {},
    loadingContext: true
});

interface LoginContextProviderProps {
    children?: any
}

export const LoginContextProvider = (props: LoginContextProviderProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [context, setContext] = useState<AuthenticationContext>({});
    const location = useLocation();

    // Retrieve authentication context
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get('ReturnUrl');

        const retrieveAuthenticationContext = async () => {
            try {
                const apiClient = new HerkePassportApiClient();
                let { result } = await apiClient.authentication_GetAuthenticationContext(returnUrl);

                if (result) {
                    setContext({
                        client: {
                            displayName: result.client?.displayName!,
                            environment: {
                                displayName: result.client?.environment?.displayName!
                            }
                        },
                        returnUrl: result.returnUrl,
                        session: result.session ? {
                            displayName: result.session?.displayName!,
                            email: result.session?.email!
                        } : null
                    });
                }
            }
            catch(error) {
                console.log(error);
            }
            finally {
                setLoading(false);
            }

        }

        if (returnUrl && !context.client) retrieveAuthenticationContext();
        else setLoading(false);
    }, [location, context.client]);

    return (
        <LoginContext.Provider value={{
            authenticationContext: context,
            loadingContext: loading
        }}>
            {props.children}
        </LoginContext.Provider>
    )
}