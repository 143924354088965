import React from 'react';
import style from './LoadingProvider.module.scss';

import { IdentityProvider } from '../../index';

interface LoadingProviderProps {
    provider?: IdentityProvider
}

export const LoadingProvider = (props: LoadingProviderProps) => {  
    return (
        <div className={style['higpp-l-login-box-loading-provider']}>
            <div className={style['higpp-l-icon-wrapper']}>
                {props.provider?.icon}
            </div>
            Je {props.provider?.displayName ?? "account"} wordt geladen...
        </div>
    )
}