import React from 'react';

import { Mail } from 'react-feather';
import { Button } from '@herke/herkeid-react';
import { IdentityProvider } from '../../index';

export interface ChooseProviderProps {
    identityProviders?: Array<IdentityProvider>,
    emailPasswordEnabled?: boolean,
    session?: {
        displayName?: string | null,
        email?: string | null
    } | null,
    onChooseProvider: (provider: IdentityProvider) => void,
}

export const ChooseProvider = (props: ChooseProviderProps) => {
    return (
        <div>
            <ul>
                {props.identityProviders?.map((provider: IdentityProvider, index: number) => {
                    return (
                        <li key={index}>
                            <Button
                                text={provider.displayName}
                                appearance="default"
                                icon={provider.icon}
                                onClick={() => props.onChooseProvider(provider)}
                                fullWidth
                            />
                        </li>
                    );
                })}
            </ul>
            {(props.identityProviders?.length! > 0) && props.emailPasswordEnabled &&
                <hr/>
            }
            {props.emailPasswordEnabled &&
                <Button
                    text="E-mail &amp; wachtwoord"
                    appearance="default"
                    icon={<Mail size={18}/>}
                    fullWidth
                    onClick={() => props.onChooseProvider({name: 'local'})}
                />
            }
        </div>
    )
}