import React, { useEffect, useState, useContext } from 'react';

import { LoginBox } from '../../components';
import { LoginBoxView } from '../../components/LoginBox';
import { LoginContext } from '../../components/LoginContextProvider';

import { ErrorMessage, PasswordResetDto } from '../../lib/herke-passport/hig-passport-api-client';
import { HerkePassportApiClient } from '../../lib';

export const PasswordResetRoute = () => {
    const { authenticationContext } = useContext(LoginContext);
    const [view, setView] = useState<LoginBoxView>(LoginBoxView.PasswordReset);

    const [error, setError] = useState<ErrorMessage>();
    const [loading, setLoading] = useState<boolean>(false);

    const { client } = authenticationContext;

    useEffect(() => {
        let params = new URLSearchParams(window.location.search),
            token = params.get("token"),
            accountId = params.get("accountId");

        if (!accountId || !token) {
            setView(LoginBoxView.Error);
            setError(new ErrorMessage({
                title: "Ongeldige aanvraag",
                body: "De aanvraag is ongeldig. Mogelijk is de link verlopen"
            }));
        }
    }, []);

    /**
     * Resets the password for the given user
     */
    const resetPassword = async (password: string) => {
        setLoading(true);

        try {
            let apiClient = new HerkePassportApiClient();
            let params = new URLSearchParams(window.location.search),
                token = params.get("token"),
                accountId = params.get("accountId");

            let result = await apiClient.account_ResetPassword(accountId!, new PasswordResetDto({
                password: password,
                recoveryToken: token
            }));

            if (result.status === 200) {
                setView(LoginBoxView.SuccessPasswordReset);
            }
        }
        catch(error) {
            setView(LoginBoxView.Error);
            setError(new ErrorMessage({
                title: "Dat ging niet goed",
                body: "Het herstellen van je wachtwoord is niet gelukt. Probeer het later opnieuw"
            }));
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <LoginBox
                appName={client?.displayName!}
                environmentName={client?.environment?.displayName!}
                loading={loading}
                onPasswordReset={resetPassword}
                error={error}
                errorReturnUrl="/recovery"
                view={view}
            />
        </div>
    );
};