import React from 'react';
import './App.css';

import { LoginRoute, SuccessRoute, ErrorRoute, PasswordResetRoute, RecoveryRoute, ConfirmEmailRoute, LogoutRoute } from './routes';
import { Switch, Route } from 'react-router';

import { LoginContextProvider } from './components';

function App() {
    return (
        <LoginContextProvider>
            <Switch>
                <Route exact path="/oauth2/v2.0/success" component={SuccessRoute}/>
                <Route exact path="/oauth2/v2.0/error" component={ErrorRoute}/>
                <Route exact path="/oauth2/v2.0/logout" component={LogoutRoute}/>
                <Route exact path="/oauth2/v2.0" component={LoginRoute}/>
                <Route exact path="/passwordreset" component={PasswordResetRoute}/>
                <Route exact path="/recovery" component={RecoveryRoute}/>
                <Route exact path="/emailconfirmation" component={ConfirmEmailRoute}/>
                <Route path="/" component={LoginRoute}/>
            </Switch>
        </LoginContextProvider>
    );
}

export default App;