import React, { useState, useEffect, useRef } from 'react';
import { Input, Form, ButtonMenu, Button, Paragraph } from '@herke/herkeid-react';

interface MFACodeProps {
    loading?: boolean,
    onBack?: () => void,
    onSubmit?: (code: string) => void
}

export const MFACode = (props: MFACodeProps) => {
    const [code, setCode] = useState("");

    const [codeError, setCodeError] = useState<string | null>("");

    const [disabled, setDisabled] = useState(true);

    const timer = useRef<any>(false);

    useEffect(() => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            if (code.length > 0)
                setCodeError("Vul een geldige code in");
            else
                setCodeError(null);
        }, 350);
    }, [code]);

    useEffect(() => {
        if (code.length > 5 && codeError === null)
            setDisabled(false);
        else
            setDisabled(true)
    }, [codeError, code])

    const onSubmit = () => {
        return props.onSubmit && props.onSubmit(code);
    }

    return (
        <div>
            <Form onSubmit={onSubmit}>
                <Paragraph>
                    Vul de code voor multi-factor beveiliging in:
                </Paragraph>
                <fieldset>
                    <Input
                        name="code"
                        value={code}
                        type="code"
                        displayName="Beveiligingscode"
                        placeholder="De code die je ontvangen hebt"
                        onChange={(e) => setCode(e.target.value)}
                        required
                        disabled={props.loading}
                        disableLastPass={false}
                    />
                </fieldset>
                <ButtonMenu align="right" className="mt-5">
                    <Button
                        text="Terug"
                        transparent
                        loading={props.loading}
                        onClick={props.onBack}
                    />
                    <Button
                        text="Bevestigen"
                        appearance="highlight"
                        type="submit"
                        loading={props.loading}
                        disabled={disabled || props.loading}
                    />
                </ButtonMenu>
            </Form>
        </div>
    );
}