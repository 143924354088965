import React from 'react';
import style from './Illustration.module.scss';

export const Illustration = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 800 411" version="1.1" style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2}}>
            <g transform="matrix(1,0,0,1,-4.54747e-13,-56.6183)">
                <g id="Illustration" transform="matrix(2.01251,0,0,2.01251,-1532.01,-577.051)">
                    <g transform="matrix(0.496892,0,0,0.496892,761.243,286.732)">
                        <rect x="0" y="56.618" width="800" height="411" style={{fill: "rgb(235,235,235)", fillOpacity: 0}}/>
                    </g>
                    <g transform="matrix(0.496892,0,0,0.496892,761.243,286.732)">
                        <path className={style['main-land']} d="M661.32,370.35C705.223,374.31 731.393,379.291 731.393,384.7C731.393,397.585 582.901,408.045 400,408.045C217.099,408.045 68.607,397.585 68.607,384.7C68.607,379.521 92.604,374.733 133.184,370.859C159.156,349.205 173.639,336.81 219.637,319.035C236.04,312.695 289.322,269.492 304.554,269.976C315.563,270.325 353.398,312.423 372.855,327.245C376.533,325.996 380.409,324.724 384.507,323.42C402.909,317.563 462.684,277.66 479.773,278.107C494.109,278.481 553.861,329.216 569.98,333.791C615.228,346.63 636.447,356.687 661.32,370.35Z" fill="currentColor"/>
                    </g>
                    <g transform="matrix(0.535212,0,0,0.644282,633.616,84.5013)">
                        <path className={style['tree-1']} d="M597.582,522.287C597.79,522.281 598.077,522.851 598.166,523.228C598.772,525.786 601.947,532.45 602.429,533.475C606.773,542.714 608.872,544.385 615.594,559.688C624.059,578.957 613.338,600.761 599.437,600.761C585.536,600.761 573.501,581.097 579.92,561.053C585.041,545.061 588.921,540.739 592.479,534.077C595.79,527.875 595.604,527.737 597.067,523.22C597.15,522.964 597.263,522.295 597.582,522.287Z"/>
                    </g>
                    <g transform="matrix(0.535212,0,0,0.644282,633.616,84.5013)">
                        <path className={style['tree-1-base']} d="M597.951,553.633L603.079,549.585L598.038,556.121L598.539,570.558L609.581,561.522L598.661,573.273L598.632,573.242L599.139,587.862L613.326,576.42L599.232,590.533L600.283,620.821L594.049,620.821L594.925,595.573L581.222,582.74L595.01,593.118L595.565,577.119L584.211,566.173L595.655,574.531L596.06,562.869L595.744,563.237L589.733,556.648L596.128,560.89L596.321,555.352L596.078,555.112L596.336,554.908L597.166,530.996L597.951,553.633Z"/>
                    </g>
                    <g transform="matrix(0.393861,0,0,0.474126,728.931,190.208)">
                        <path className={style['tree-2']} d="M597.582,522.287C597.79,522.281 598.077,522.851 598.166,523.228C598.772,525.786 601.947,532.45 602.429,533.475C606.773,542.714 608.872,544.385 615.594,559.688C624.059,578.957 613.338,600.761 599.437,600.761C585.536,600.761 573.501,581.097 579.92,561.053C585.041,545.061 588.921,540.739 592.479,534.077C595.79,527.875 595.604,527.737 597.067,523.22C597.15,522.964 597.263,522.295 597.582,522.287Z"/>
                    </g>
                    <g transform="matrix(0.393861,0,0,0.474126,728.931,190.208)">
                        <path className={style['tree-2-base']} d="M597.951,553.633L603.079,549.585L598.038,556.121L598.539,570.558L609.581,561.522L598.661,573.273L598.632,573.242L599.139,587.862L613.326,576.42L599.232,590.533L600.283,620.821L594.049,620.821L594.925,595.573L581.222,582.74L595.01,593.118L595.565,577.119L584.211,566.173L595.655,574.531L596.06,562.869L595.744,563.237L589.733,556.648L596.128,560.89L596.321,555.352L596.078,555.112L596.336,554.908L597.166,530.996L597.951,553.633Z"/>
                    </g>
                    <g transform="matrix(1.00574,0,0,0.920189,108.204,-59.0173)">
                        <rect className={style['building-2']} x="870.739" y="443.749" width="57" height="134.001"/>
                    </g>
                    <g transform="matrix(0.696504,0,0,0.628105,414.946,109.732)">
                        <rect className={style['building-1']} x="870.739" y="443.749" width="57" height="134.001"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}