import React, { ReactElement, ReactFragment } from 'react';
import style from './Success.module.scss';

import { CheckCircle } from 'react-feather';
import { Button, ButtonMenu, Heading, Paragraph } from '@herke/herkeid-react';

interface SuccessProps {
    title?: string,
    message?: string,
    buttons?: ReactElement[] | ReactElement | ReactFragment
}

const SuccessBase = (props: SuccessProps) => {
    return (
        <div className={style['higpp-l-login-box-success']}>
            <div className={style['success-icon']}>
                <CheckCircle/>
            </div>
            <Heading type="h2" className="mt-5">{props.title}</Heading>
            <Paragraph className="my-2">{props.message}</Paragraph>
            {props.buttons &&
                <ButtonMenu className="mt-10" align="center">
                    {props.buttons}
                </ButtonMenu>
            }
        </div>
    )
}

export const Success = () => {
    return <SuccessBase/>;
}

export const SuccessRegistration = () => {
    return (
        <SuccessBase
            title="Account aangemaakt!"
            message="Je account is succesvol aangemaakt. We sturen je nu terug naar de applicatie."
        />
    );
}

export const SuccessSignout = () => {
    return (
        <SuccessBase
            title="Uitgelogd!"
            message="Je bent succesvol uitgelogd."
        />
    );
}

export const SuccessEmailConfirmation = () => {
    return (
        <SuccessBase
            title="Bevestigd!"
            message="Bedankt, we hebben je e-mailadres succesvol kunnen bevestigen."
        />
    );
}

export const SuccessRecovery = (props: { onBack?: () => void }) => {
    return (
        <SuccessBase
            title="Bekijk je e-mail"
            message="Als je account bestaat ontvang je zo een bericht met verdere instructies."
            buttons={props.onBack ? [
                <Button
                    text="Terug"
                    appearance="default"
                    onClick={props.onBack}
                />
            ] : []}
        />
    );
}

export const SuccessPasswordReset = () => {
    return (
        <SuccessBase
            title="Gelukt!"
            message="Je wachtwoord is aangepast. Je kan nu opnieuw inloggen."
        />
    );
}

export const SuccessExternalSignin = () => {
    return (
        <SuccessBase
            title="Welkom terug!"
        />
    );
}

export const SuccessExternalRegistration = () => {
    return (
        <SuccessBase
            title="Account aangemaakt!"
            message="Je account is succesvol aangemaakt. We sturen je nu terug naar de applicatie."
        />
    );
}

export const SuccessExternalLink = () => {
    return (
        <SuccessBase
            title="Account gekoppeld!"
            message="Je account is succesvol gekoppeld aan je Herke Account. We sturen je nu terug naar de applicatie."
        />
    );
}

export const SuccessContinueSession = () => {
    return (
        <SuccessBase
            title="Helemaal goed!"
            message="We sturen je terug naar de applicatie."
        />
    );
}

SuccessBase.defaultProps = {
    title: 'Welkom',
    message: "We sturen je terug naar de applicatie."
}