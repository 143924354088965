import React, { useState, useContext } from 'react';

import { LoginBox } from '../../components';
import { LoginBoxView } from '../../components/LoginBox';
import { LoginContext } from '../../components/LoginContextProvider';
import { AccountRecoveryDto } from '../../lib/herke-passport/hig-passport-api-client';
import { HerkePassportApiClient } from '../../lib';

export const RecoveryRoute = () => {
    const { authenticationContext } = useContext(LoginContext);
    const [view, setView] = useState<LoginBoxView>(LoginBoxView.Recovery);

    const [loading, setLoading] = useState<boolean>(false);

    const { client } = authenticationContext;

    const recovery = async (username: string) => {
        setLoading(true);

        try {
            let apiClient = new HerkePassportApiClient();

            await apiClient.account_Recovery(new AccountRecoveryDto({
                username: username
            }));
        }
        catch(error) {
            console.log(error);
        }
        finally {
            setView(LoginBoxView.SuccessRecovery);
        }
    }

    return (
        <div>
            <LoginBox
                appName={client?.displayName!}
                environmentName={client?.environment?.displayName!}
                onRecovery={recovery}
                onChooseProvider={() => setView(LoginBoxView.EmailPassword)}
                loading={loading}
                view={view}
            />
        </div>
    );
};