import React, { useState, useContext, useEffect } from 'react';
import { LoginBox, MicrosoftLogo } from '../../components';
import { LoadingOverlay } from '@herke/herkeid-react';
import { LoginContext } from '../../components/LoginContextProvider';
import { SignInDto, CreateAccountDto, AccountRecoveryDto, ErrorMessage, ErrorDetails } from '../../lib/herke-passport/hig-passport-api-client';
import { LoginBoxView, IdentityProvider } from '../../components/LoginBox';
import { useLocation } from 'react-router';
import { NewUserInfo } from '../../components/LoginBox/views/Registration';
import { HerkePassportApiClient, validateEmail, validatePassword } from '../../lib';

/** Main login route */
export const LoginRoute = () => {
    const { loadingContext, authenticationContext } = useContext(LoginContext);
    const location = useLocation();
    
    const [view, setView] = useState<LoginBoxView>(LoginBoxView.Loading);
    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<ErrorMessage>();

    const { client, session, returnUrl } = authenticationContext;

    useEffect(() => {
        const setCorrectView = () => {
            if (!client) {
                setError(new ErrorMessage({
                    title: "Ongeldige aanvraag",
                    body: "Dat ging niet helemaal goed. Probeer het later opnieuw.",
                    details: new ErrorDetails({
                        referenceId: 'HIG-PI-400.1'
                    })
                }));
        
                setView(LoginBoxView.Error);
            }
            else if (session != null)
                setView(LoginBoxView.ChooseSession);
            else if(location.pathname.includes("/register")) {
                setView(LoginBoxView.Register);
            }
            else {
                setView(LoginBoxView.ChooseProvider);
            }

        }

        setCorrectView();
    }, [client, session, location.pathname]);

    /** Handles signin with email/password combo */
    const emailPassword = async ({ username, password, isPersistent } : { username: string, password: string, isPersistent?: boolean})
        : Promise<{result: boolean, message: string}> => {

        const params = new URLSearchParams(window.location.search);
        setLoading(true);

        try {
            const apiClient = new HerkePassportApiClient();
            const redirectUri = params.get("ReturnUrl");

            if (!redirectUri) return { result: false, message: "Ongeldig verzoek"}; // Throw something!

            let response = await apiClient.authentication_SignIn(new SignInDto({
                userName: username,
                password: password,
                returnUrl: params.get("ReturnUrl"),
            }));

            if (response.status === 200) {
                setView(LoginBoxView.Success);
                
                setTimeout(() => {
                    window.location.href = returnUrl!;
                }, 750);

                return { result: true, message: "" };
            }
        }
        catch(error) {
            let message = "";

            switch(error.response.substring(1, error.response.length-1)) {
                case "Invalid credentials":
                    message = "Dat klopt hiet helemaal"; break;
                case "Account locked":
                    setView(LoginBoxView.AccountLocked);
                    setTimeout(() => {
                        setView(LoginBoxView.EmailPassword);
                    }, 300000);
            }

            setLoading(false);
            return { result: false, message: message };
        }

        return { result: false, message: "Dat klopte niet helemaal"};
    }

    /** Handles when a user chooses a specific login provider */
    const chooseProvider = (provider: IdentityProvider) => {
        // Local means username/password
        if (provider.name === 'local') {
            setView(LoginBoxView.EmailPassword);
        }
        else {
            setView(LoginBoxView.LoadingProvider);
            
            setTimeout(() => {
                window.location.href = `/signin/${provider?.name}?ReturnUrl=${encodeURIComponent(returnUrl!)}`
            }, 750);
        }
    }

    /** Handles when a user chooses to continue with the current session */
    const continueWithSession = () => {
        setView(LoginBoxView.SuccessContinueSession);

        setTimeout(() => {
            window.location.href = `/continueWithSession?ReturnUrl=${encodeURIComponent(returnUrl!)}`;
        }, 750);
    }

    /** Handles when a user wants to return to the provider picker */
    const chooseProviderView = () => {
        setView(LoginBoxView.ChooseProvider)
    }

    const register = async (userInfo: NewUserInfo) => {
        const params = new URLSearchParams(window.location.search);
        setLoading(true);

        try {
            const apiClient = new HerkePassportApiClient();
            const redirectUri = params.get("ReturnUrl");

            if (!redirectUri) return; // Throw something!

            let response = await apiClient.account_Create(new CreateAccountDto({
                email: userInfo.username,
                password: userInfo.password,
                givenName: userInfo.givenName,
                familyName: userInfo.familyName
            }));

            if (response.status === 200) {
                setView(LoginBoxView.ConfirmEmail);
    
                let response = await apiClient.authentication_SignIn(new SignInDto({
                    userName: userInfo.username,
                    password: userInfo.password,
                    returnUrl: params.get("ReturnUrl"),
                }));

                if (response.status === 200) {
                    setTimeout(() => {
                        setView(LoginBoxView.Success);
                    
                        setTimeout(() => {
                            window.location.href = returnUrl!;
                        }, 1500);
                    }, 10000);
                }
            }
        }
        catch(error) {
            switch(error.response.substring(1, error.response.length-1)) {
                case "Account locked":
                    setView(LoginBoxView.AccountLocked);
                    setTimeout(() => {
                        setView(LoginBoxView.EmailPassword);
                    }, 300000);
            }

            setLoading(false);
        }
    }

    const recovery = async (username: string) => {
        try {
            let apiClient = new HerkePassportApiClient();

            await apiClient.account_Recovery(new AccountRecoveryDto({
                username: username
            }))
        }
        catch(error) {
            console.log(error);
        }
        finally {
            setView(LoginBoxView.SuccessRecovery);
        }
    }

    const validateUsername = async (username: string) => {
        // If it's not an e-mail address, block here
        if (!validateEmail(username)) return {
            isValid: false,
            message: "Vul een geldig e-mailadres in"
        };

        // Otherwise, check if the address is already in use
        try {
            let apiClient = new HerkePassportApiClient();

            let result = await apiClient.account_CheckAvailability(username);

            return {
                isValid: result.result.isAvailable!,
                message: !result.result.isAvailable
                    ? "Dit account bestaat al"
                    : ""
            };
        }
        catch(error) {
            console.log(error);
        }

        return {
            isValid: false,
            message: "Ongeldige aanvraag"
        };
    }

    // If we are still waiting for the client information, show the page loader
    if (!client && loadingContext) {
        return (
            <LoadingOverlay/>
        );
    }

    return (
        <div>
            <LoginBox
                view={view}
                appName={client?.displayName}
                environmentName={client?.environment?.displayName!}
                session={session!}
                loading={loading}

                error={error}

                identityProviders={[
                    {
                        name: 'microsoft',
                        displayName: "Microsoft Account",
                        icon: <MicrosoftLogo size={20}/>
                    }
                ]}
                onChooseProviderView={chooseProviderView}
                onLoginView={() => {
                    setView(LoginBoxView.EmailPassword);
                    setLoading(false);
                }}
                onChooseProvider={chooseProvider}
                onEmailPassword={emailPassword}
                onContinueWithSession={continueWithSession}
                onRegister={register}
                onRecovery={recovery}

                onValidateRegistrationUsername={validateUsername}
                onValidateRegistrationPassword={validatePassword}

                onRegisterView={() => setView(LoginBoxView.Register)}
                onRecoveryView={() => setView(LoginBoxView.Recovery)}
            />
        </div>
    )
}