import React, { useState, useEffect, useRef } from 'react';
import { Input, Form, ButtonMenu, Button } from '@herke/herkeid-react';

import { validateEmail, validatePassword } from './../../../../lib';

interface EmailPasswordProps {
    loading?: boolean,
    signinError?: string | null,
    onBack?: () => void,
    onSubmit?: ({username, password, isPersistent} : { username: string, password: string, isPersistent?: boolean}) => Promise<{result: boolean, message: string}>
}

export const EmailPassword = (props: EmailPasswordProps) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [usernameError, setUsernameError] = useState<string | null>("");
    const [passwordError, setPasswordError] = useState<string | null>("");

    const [disabled, setDisabled] = useState(true);

    const timer = useRef<any>(false);

    useEffect(() => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            if (username.length > 0 && !validateEmail(username))
                setUsernameError("Vul een geldig e-mailadres in");
            else
                setUsernameError(null);

            if (password.length > 0 && !validatePassword(password))
                setPasswordError("Vul een geldig wachtwoord in");
            else
                setPasswordError(null);
        }, 350);
    }, [username, password]);

    useEffect(() => {
        if (username.length > 0 && password.length > 5 && passwordError === null && usernameError === null)
            setDisabled(false);
        else
            setDisabled(true)
    }, [usernameError, passwordError, username, password])

    const onSubmit = async () => {
        if(props.onSubmit) {
            let response = await props.onSubmit({
                username: username,
                password: password,
                isPersistent: true
            });

            if (!response.result) {
                setUsernameError(response.message);
            }
        }
    }

    return (
        <div>
            <Form onSubmit={onSubmit}>
                <fieldset>
                    <Input
                        name="username"
                        value={username}
                        type="email"
                        displayName="E-mailadres"
                        placeholder="e.mail@adres.nl"
                        onChange={(e) => setUsername(e.target.value)}
                        maxLength={75}
                        maxLengthIndicator={false}
                        message={usernameError ? {
                            body: usernameError,
                            type: "error"
                        } : undefined}
                        autoFocus
                        required
                        disabled={props.loading}
                        disableLastPass={false}
                    />
                </fieldset>
                <fieldset>
                    <Input
                        name="password"
                        value={password}
                        type="password"
                        displayName="Wachtwoord"
                        placeholder="Je wachtwoord"
                        maxLength={255}
                        maxLengthIndicator={false}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        disabled={props.loading}
                        disableLastPass={false}
                    />
                </fieldset>
                <ButtonMenu align="center" className="mt-5">
                    <Button
                        text="Terug"
                        transparent
                        disabled={props.loading}
                        onClick={props.onBack}
                        className="mr-5"
                        fullWidth
                    />
                    <Button
                        text="Inloggen"
                        appearance={props.signinError ? "error" : "highlight"}
                        type="submit"
                        loading={props.loading}
                        disabled={disabled || props.loading}
                        className="ml-3"
                        fullWidth
                    />
                </ButtonMenu>
            </Form>
        </div>
    );
}