import React, { useEffect, useState, useContext } from 'react';

import { LoginBox } from '../../components';
import { LoginBoxView } from '../../components/LoginBox';
import { LoadingOverlay } from '@herke/herkeid-react';
import { LoginContext } from '../../components/LoginContextProvider';

export const SuccessRoute = () => {
    const { authenticationContext } = useContext(LoginContext);
    const [view, setView] = useState<LoginBoxView>(LoginBoxView.Success);

    const { returnUrl, client } = authenticationContext;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');

        switch(type) {
            case "local": setView(LoginBoxView.Success); break;
            case "external": setView(LoginBoxView.SuccessExternalSignIn); break;
            case "external-link": setView(LoginBoxView.SuccessExternalLink); break;
            case "external-registration": setView(LoginBoxView.SuccessExternalRegistration); break;
        }
    }, []);

    // Retrieve authentication context
    useEffect(() => {
        if (returnUrl)
            setTimeout(() => {
                window.location.href = returnUrl!;
            }, 1500);
    }, [client, returnUrl]);

    return (
        <div>
            {!client ? (
                <LoadingOverlay/>
            ) : (
                <LoginBox
                    appName={client?.displayName!}
                    environmentName={client?.environment?.displayName!}
                    view={view}
                />
            )}
        </div>
    );
};