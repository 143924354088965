/** Basic password validation */
export const validatePassword = (password: string): { isValid: boolean, message: string } => {
    let format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        isValid: boolean = false,
        hasLength: boolean = password.length > 7,
        hasSpecialChar: boolean = format.test(password),
        hasCapital: boolean = (/[A-Z]+/.test(password)),
        message: string = "";

    if (!hasLength && !hasSpecialChar && !hasCapital)
        message = "Voeg minimaal 8 karakters, een speciaal teken en hoofdletter toe"
    else if (!hasLength)
        message = "Voeg minimaal 8 karakters toe";
    else if (!hasSpecialChar)
        message = "Voeg een speciaal teken toe";
    else
        isValid = true;

    return {
        isValid: isValid,
        message: message
    };
}

/** Basic validation to prevent spamming the API */
export const validateEmail = (email: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (re.test(String(email).toLowerCase())) {
        return true;
    }

    return false;
}