import React, { useContext, useEffect } from 'react';
import style from './Error.module.scss';

import { CodeBlock, Error as ErrorComponent, Paragraph, ToastContext } from '@herke/herkeid-react';
import { ErrorMessage } from '../../../../lib/herke-passport/hig-passport-api-client';
import { NavLink } from 'react-router-dom';

interface ErrorProps {
    error?: ErrorMessage,
    errorReturnUrl?: string,
}

export const Error = (props: ErrorProps) => {
    const { addToast } = useContext(ToastContext);

    useEffect(() => {
        if (props.error?.developerDetails?.message) {
            addToast({
                displayName: "Developers info",
                body: "Deze loginaanvraag is ongeldig. Bekijk de volledige foutmelding in de management console"
            });

            setTimeout(() => {
                addToast({
                    displayName: "Developers info",
                    body: (
                        <>
                            <Paragraph>Onderstaande informatie helpt je wellicht verder:</Paragraph>
                            <CodeBlock highlight={false} body={JSON.stringify(props.error?.developerDetails, null, 4)}/>
                        </>
                    )
                });
            }, 50);
        }
    }, [props.error, addToast]);

    return (
        <div className={style['higpp-l-login-box-error']}>
            <ErrorComponent
                {...props.error as ErrorProps}
                buttons={props.errorReturnUrl ? [
                    <NavLink to={props.errorReturnUrl}>Terug</NavLink>
                ] : []}
            />
        </div>
    );
}