import React, { useState, useEffect, useRef } from 'react';
import style from './Registration.module.scss';
import { Form, Input, ButtonMenu, Button, Paragraph } from '@herke/herkeid-react';

import { AnimatePresence } from 'framer-motion';
import { AnimateDiv } from '../../index';

interface RegistrationProps {
    loading?: boolean,
    onValidateRegistrationUsername?: (username: string) => Promise<{isValid: boolean, message: string}> | {isValid: boolean, message: string},
    onValidateRegistrationPassword?: (password: string) => Promise<{isValid: boolean, message: string}> | {isValid: boolean, message: string},
    onSubmit?: (newUser: NewUserInfo) => void,
    onBack?: () => void
}

export interface NewUserInfo {
    givenName?: string,
    familyName?: string,
    username?: string,
    password?: string,
}

enum RegistrationStep {
    PersonalInformation,
    UsernamePassword,
    Confirmation
}

export const Registration = (props: RegistrationProps) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");

    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [givenNameError, setGivenNameError] = useState<string | null>(null);
    const [familyNameError, setFamilyNameError] = useState<string | null>(null);

    const [usernameChanged, setUsernameChanged] = useState<boolean>(false);

    const [disabled, setDisabled] = useState(true);

    const [step, setStep] = useState<RegistrationStep>(RegistrationStep.PersonalInformation);

    const timer = useRef<any>();

    /** Validating of personal info values */
    useEffect(() => {
        if (step === RegistrationStep.PersonalInformation) {
            if (givenName.length > 0 && familyName.length > 1 && familyNameError === null && givenNameError === null)
                setDisabled(false);
            else {
                clearTimeout(timer.current);

                timer.current = setTimeout(() => {
                    setDisabled(true);

                    setGivenNameError(
                        givenName.length < 2 && givenName.length !== 0
                            ? "Vul je voornaam in"
                            : null
                    )

                    setFamilyNameError(
                        familyName.length < 1 
                            ? "Vul je achternaam in"
                            : null
                    )
                }, 350);
            }
        }
    }, [givenName, givenNameError, familyName, familyNameError, step]);

    useEffect(() => {
        setUsernameError(null);
        setUsernameChanged(true);
    }, [username])

    useEffect(() => {
        setPasswordError(null);
    }, [password])

    const validateEmailPassword = async () => {
        let usernameValid: boolean = false,
            passwordValid: boolean = false;

        // Prevent calling the external service multiple times
        // if the username didn't change
        if (usernameChanged && props.onValidateRegistrationUsername) {
            let result = await Promise.resolve(props.onValidateRegistrationUsername(username));

            if (result.isValid) {
                usernameValid = true;
                setUsernameChanged(false);
                setUsernameError("");
            }
            else setUsernameError(result.message!);
        }
        if (props.onValidateRegistrationPassword) {
            let result = await Promise.resolve(props.onValidateRegistrationPassword(password));

            if (result.isValid) {
                passwordValid = true;
                setPasswordError("");
            } 
            else setPasswordError(result.message!);
        }

        if ((usernameValid || !usernameChanged) && passwordValid)
            setStep(RegistrationStep.Confirmation);
    }

    const onSubmit = () => {       
        props.onSubmit && props.onSubmit({
            username: username,
            password: password,
            givenName: givenName,
            familyName: familyName
        });
    }

    return (
        <div>
            <Form onSubmit={onSubmit}>
                <AnimatePresence>
                {{
                    [RegistrationStep.PersonalInformation]:
                        <AnimateDiv id={1} key={1}>
                            <div>
                                <Paragraph>Maak een nieuw account aan:</Paragraph>
                                <fieldset>
                                    <Input
                                        name="givenName"
                                        value={givenName}
                                        displayName="Voornaam"
                                        placeholder="Voornaam"
                                        onChange={(e) => setGivenName(e.target.value)}
                                        message={givenNameError ? {
                                            body: givenNameError,
                                            type: "error"
                                        } : undefined}
                                        maxLength={100}
                                        maxLengthIndicator={false}
                                        autoFocus
                                        required
                                        disabled={props.loading}
                                    />
                                </fieldset>
                                <fieldset>
                                    <Input
                                        name="familyName"
                                        value={familyName}
                                        type="familyName"
                                        displayName="Achternaam"
                                        placeholder="Achternaam (inclusief tussenvoegsel)"
                                        onChange={(e) => setFamilyName(e.target.value)}
                                        maxLength={100}
                                        maxLengthIndicator={false}
                                        required
                                        disabled={props.loading}
                                    />
                                </fieldset>
                                <ButtonMenu align="center" className="mt-10">
                                    <Button
                                        text="Terug"
                                        transparent
                                        loading={props.loading}
                                        onClick={props.onBack}
                                        className="mr-5"
                                        fullWidth
                                    />
                                    <Button
                                        text="Volgende"
                                        appearance="highlight"
                                        loading={props.loading}
                                        disabled={disabled || props.loading}
                                        onClick={() => setStep(RegistrationStep.UsernamePassword)}
                                        className="ml-3"
                                        fullWidth
                                    />
                                </ButtonMenu>
                            </div>
                        </AnimateDiv>,
                    [RegistrationStep.UsernamePassword]:
                        <AnimateDiv id={2} key={2}>
                            <div>
                                <Paragraph>Vul een e-mailadres en wachtwoord in waarmee je straks kan inloggen:</Paragraph>
                                <fieldset>
                                    <Input
                                        name="username"
                                        value={username}
                                        type="email"
                                        displayName="E-mailadres"
                                        placeholder="e.mail@adres.nl"
                                        maxLength={75}
                                        maxLengthIndicator={false}
                                        onChange={(e) => setUsername(e.target.value)}
                                        message={usernameError ? {
                                            body: usernameError,
                                            type: "error"
                                        } : undefined}
                                        autoFocus
                                        required
                                        disabled={props.loading}
                                    />
                                </fieldset>
                                <fieldset>
                                    <Input
                                        name="password"
                                        value={password}
                                        type="password"
                                        maxLength={255}
                                        maxLengthIndicator={false}
                                        displayName="Wachtwoord"
                                        placeholder="Je wachtwoord"
                                        message={passwordError ? {
                                            body: passwordError,
                                            type: "error"
                                        } : undefined}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        disabled={props.loading}
                                    />
                                </fieldset>
                                <ButtonMenu align="center" className="mt-5">
                                    <Button
                                        text="Terug"
                                        transparent
                                        loading={props.loading}
                                        onClick={() => setStep(RegistrationStep.PersonalInformation)}
                                        className="mr-5"
                                        fullWidth
                                    />
                                    <Button
                                        text="Volgende"
                                        appearance="highlight"
                                        loading={props.loading}
                                        disabled={disabled}
                                        onClick={() => validateEmailPassword()}
                                        className="ml-3"
                                        fullWidth
                                    />
                                </ButtonMenu>
                            </div>
                        </AnimateDiv>,
                    [RegistrationStep.Confirmation]:
                        <AnimateDiv id={3} key={3}>
                            <div>
                                <Paragraph>Controleer of alles klopt:</Paragraph>
                                <dl className={style['confirmation-list']}>
                                    <dt>Naam:</dt>
                                    <dd>{givenName + " " + familyName}</dd>
                                    <dt>E-mailadres:</dt>
                                    <dd>{username}</dd>
                                </dl>
                                <ButtonMenu align="center" className="mt-10">
                                    <Button
                                        text="Terug"
                                        transparent
                                        disabled={props.loading}
                                        onClick={() => setStep(RegistrationStep.UsernamePassword)}
                                        className="mr-5"
                                        fullWidth
                                    />
                                    <Button
                                        text="Account maken"
                                        appearance="highlight"
                                        loading={props.loading}
                                        loadingText="Account maken"
                                        disabled={disabled}
                                        type="submit"
                                        className="ml-3"
                                        fullWidth
                                    />
                                </ButtonMenu>
                            </div>
                        </AnimateDiv>,
                }[step]}
                </AnimatePresence>
            </Form>
        </div>
    );
}