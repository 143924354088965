import React, { useState, useEffect, useRef } from 'react';
import { Input, Form, ButtonMenu, Button, Paragraph } from '@herke/herkeid-react';

import { validatePassword } from './../../../../lib';

interface PasswordResetProps {
    loading?: boolean,
    onSubmit?: (password: string) => void,
    onBack?: () => void
}

export const PasswordReset = (props: PasswordResetProps) => {
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    const [passwordError, setPasswordError] = useState<string | null>("");
    const [passwordRepeatError, setPasswordRepeatError] = useState<string | null>("");

    const [disabled, setDisabled] = useState(true);

    const timer = useRef<any>(false);

    useEffect(() => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            if (password.length > 0 && !validatePassword(password))
                setPasswordError("Vul een geldig wachtwoord in");
            else
                setPasswordError(null);

            if (passwordRepeat.length > 0 && passwordRepeat !== password)
                setPasswordRepeatError("Herhaal het wachtwoord");
            else
                setPasswordRepeatError(null);
        }, 350);
    }, [password, passwordRepeat]);

    useEffect(() => {
        if (passwordRepeat.length > 0 && password.length > 5 && passwordError === null && passwordRepeatError === null && password === passwordRepeat)
            setDisabled(false);
        else
            setDisabled(true)
    }, [passwordRepeatError, passwordError, passwordRepeat, password])

    const submit = () => {
        props.onSubmit && props.onSubmit(password);
    }

    return (
        <div>
            <Form onSubmit={submit}>
                <Paragraph className="mt-5">
                    Kies een nieuw wachtwoord:
                </Paragraph>
                <fieldset>
                    <Input
                        name="new-password"
                        value={password}
                        displayName="Nieuw wachtwoord"
                        placeholder="***********"
                        maxLength={255}
                        maxLengthIndicator={false}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        message={passwordError ? {
                            body: passwordError,
                            type: "error"
                        } : undefined}
                        autoFocus
                        disabled={props.loading}
                    />
                </fieldset>
                <fieldset className="mt-2">
                    <Input
                        name="new-password-repeat"
                        value={passwordRepeat}
                        displayName="Herhaal wachtwoord"
                        placeholder="***********"
                        maxLength={255}
                        maxLengthIndicator={false}
                        type="password"
                        onChange={(e) => setPasswordRepeat(e.target.value)}
                        message={passwordRepeatError ? {
                            body: passwordRepeatError,
                            type: "error"
                        } : undefined}
                        disabled={props.loading}
                    />
                </fieldset>
                <ButtonMenu align="center" className="mt-10">
                    {props.onBack &&
                        <Button
                            text="Terug"
                            transparent
                            loading={props.loading}
                            onClick={props.onBack}
                            className="mr-5"
                            fullWidth
                        />
                    }
                    <Button
                        text="Bevestigen"
                        appearance="highlight"
                        loading={props.loading}
                        loadingText="Opslaan"
                        disabled={disabled || props.loading}
                        type="submit"
                        fullWidth
                    />
                </ButtonMenu>
            </Form>
        </div>
    );
}